import { PlainMessage } from "@bufbuild/protobuf";
import { EventSeries } from "@egocentric-systems/ts-apis/booking_gateway/v1/events_pb";
import SeriesSlider from "./SeriesSlider";

interface IPromotedSeries {
  series: PlainMessage<EventSeries>;
}

export function PromotedSeries({ series }: Readonly<IPromotedSeries>): JSX.Element {
  return (
    <div className="flex flex-col gap-2">
      <div className="container">
        <h1 className="text-xl font-bold">{series.name}</h1>
        <p className="text-md">{series.intro}</p>
      </div>
      <SeriesSlider series={series} promotedSeries />
    </div>
  );
}
