"use client";

import { useTranslations } from "~/hooks/useTranslations";
import adac from "~/assets/ADAC.svg";
import Image from "next/image";

export function DTMMembershipBanner(): JSX.Element {
  const t = useTranslations();

  return (
    <div className="container w-full flex items-center justify-center">
      <div className="flex flex-col gap-4 w-full max-w-[900px] p-6  border rounded-[16px] bg-[#DCDFDC]">
        <div className="flex gap-2">
          <Image
            src={adac}
            alt="ADAC Logo"
            width={250}
            height={250}
            className="object-contain"
          />
          <h2 className="font-bold text-lg content-center">
            {t.dtm.membership.title}
          </h2>
        </div>
        <div dangerouslySetInnerHTML={{ __html: t.dtm.membership.description }} />
      </div>
    </div>
  );
}
