"use client";

import { useMemo } from "react";
import { useMediaQuery } from "usehooks-ts";
import { useShopConfig } from "~/hooks/useShopConfig";
import { useTranslations } from "~/hooks/useTranslations";
import { cn } from "~/lib/utils";
import Image from "next/image";
import Link from "next/link";
import { Paths } from "~/lib/Paths";

export function FanticketBanner(): JSX.Element {
  const t = useTranslations();
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const {
    shopConfig: {
      fanTicket,
    },
  } = useShopConfig();

  const thumbnail = useMemo(() => {
    return fanTicket?.imageVariants.reduce((prev, current) =>
      Number(prev.dimensions?.width) > Number(current.dimensions?.width)
        ? prev
        : current,
    );
  }, [fanTicket]);

  return fanTicket ? (
    <div className={cn(isDesktop && "py-50px", "w-full flex items-center justify-center bg-gradient-to-r from-black to-[#626262]")}>
      <div className="flex flex-col md:flex-row gap-4 w-full max-w-[900px] p-6">
        <div className="md:w-1/2">
          <Image
            width={thumbnail?.dimensions?.width}
            height={thumbnail?.dimensions?.height}
            alt={thumbnail?.url ?? ""}
            src={thumbnail?.url ?? ""}
            className="w-full h-full object-cover rounded-[12px]"
          />
        </div>
        <div className="md:w-1/2 flex flex-col justify-center p-4 text-white">
          <h2
            className={
              cn(isDesktop ? "text-2xl" : "text-md",
                "h-full w-[50%] text-white font-bold content-center"
              )
            }
          >
            {t.additional_options.fanticket}
          </h2>
          <p>{t.additional_options.description}</p>
          <Link
            href={Paths.FANTICKET}
            className={cn(isDesktop ? "max-w-[200px]" : "w-full", "flex border border-black rounded-[4px] mt-4 p-2 justify-center bg-black")}
          >
            <span className={cn(isDesktop ? "text-lg" : "text-sm", "font-bold")}>
              {t.additional_options.inform_now}
            </span>
          </Link>
        </div>
      </div>
    </div >
  ) : <></>;
}
