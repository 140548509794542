import { PlainMessage } from "@bufbuild/protobuf";
import { EventSeries } from "@egocentric-systems/ts-apis/booking_gateway/v1/events_pb";
import { useShopConfig } from "~/hooks/useShopConfig";
import { cn, getLargestVariant } from "~/lib/utils";
import { SeriesSliderCard } from "./SeriesSliderCard";
import Link from "next/link";
import Image from "next/image";
import { generatePath } from "~/lib/client/utils";
import { Paths } from "~/lib/Paths";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarStar } from "@fortawesome/pro-solid-svg-icons";
import { useTranslations } from "~/hooks/useTranslations";

interface ISeriesGrid {
  series: PlainMessage<EventSeries>;
  maxCards: number;
  promotedSeries?: boolean;
}

export function SeriesGrid({
  series,
  maxCards,
  promotedSeries,
}: Readonly<ISeriesGrid>): JSX.Element {
  const d = useTranslations();
  const { shopConfig } = useShopConfig();
  const overviewThumbnail = getLargestVariant(series.thumbnail);

  const maxBundles = series.bundles.slice(0, maxCards);
  const remainingCards = maxCards - maxBundles.length;
  const totalEvents = series.events.length + series.bundles.length;
  const additionalEvents =
    remainingCards > 0 ? series.events.slice(0, remainingCards) : [];

  const combinedEvents = [...maxBundles, ...additionalEvents];
  return (
    <>
      {combinedEvents.map((serie) => (
        <div
          key={serie.id}
          role="tabpanel"
          className={cn(
            promotedSeries
              ? "border-black bg-black text-white"
              : "bg-gray-200 text-black",
            "flex h-[290px] w-full max-w-[19%] flex-col gap-3 rounded-lg border duration-200 sm:max-w-[150px] md:min-w-[32%] md:max-w-[32%] md:hover:h-[330px] lg:min-w-[24%] lg:max-w-[24%] xl:min-w-[19%] xl:max-w-[19%]",
          )}
        >
          <SeriesSliderCard series={serie} />
        </div>
      ))}
      <Link
        href={generatePath(Paths.SERIES, { seriesId: series.id })}
        prefetch={false}
        className="grid w-[19%]"
      >
        <div className="relative hidden h-[290px] items-center justify-end overflow-hidden rounded-lg duration-200 hover:h-[325px] xl:flex">
          <Image
            src={overviewThumbnail?.url ?? ""}
            alt="Events"
            fill
            className="object-cover"
          />
          <div className="absolute inset-0 bg-black opacity-60" />
          <div className="absolute inset-0 flex flex-col items-center justify-center gap-3">
            <FontAwesomeIcon
              icon={faCalendarStar}
              style={{
                color: shopConfig?.commonConfiguration?.sloganTextColor,
              }}
              className="size-12"
            />
            <p
              style={{
                color: shopConfig?.commonConfiguration?.sloganTextColor,
              }}
              className="text-center underline underline-offset-2"
            >
              {totalEvents > maxCards
                ? d.start_page.view_all_events.replace(
                    "{count}",
                    totalEvents.toString(),
                  )
                : d.start_page.to_overview}
            </p>
          </div>
        </div>
      </Link>
    </>
  );
}
