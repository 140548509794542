"use client";

import { useShopConfig } from "~/hooks/useShopConfig";
import Image from "next/image";
import { useMemo } from "react";
import { useTranslations } from "~/hooks/useTranslations";
import Link from "next/link";
import { buttonVariants } from "./ui/button";
import { cn } from "~/lib/utils";
import { generatePath } from "~/lib/client/utils";
import { Paths } from "~/lib/Paths";
import { useMediaQuery } from "usehooks-ts";

export function GiftcardInfoBanner(): JSX.Element {
  const { shopConfig: { commonConfiguration, giftcardOptions } } = useShopConfig();
  const t = useTranslations();
  const isMobile = useMediaQuery("(max-width: 767px)");

  const thumbnail = useMemo(() => {
    return giftcardOptions?.salesImage?.variants.reduce((prev, current) =>
      Number(prev?.width) > Number(current.width) ? prev : current,
    );
  }, [giftcardOptions]);

  return giftcardOptions ? (
    <div className={cn(!isMobile && "py-50px", "w-full flex items-center justify-center bg-gradient-to-r from-black to-[#626262]")}>
      <div className="flex flex-col md:flex-row gap-4 w-full max-w-[900px] p-6">
        <div className="md:w-1/2">
          <Image
            width={thumbnail?.width}
            height={thumbnail?.height}
            alt="Giftcard"
            src={thumbnail?.url ?? ""}
            className="w-full h-full object-cover rounded-[12px]"
          />
        </div>
        <div className="md:w-1/2 flex flex-col justify-center p-4 text-white">
          <h2 className="font-bold text-xl">{t.giftcards.info_banner_title}</h2>
          <p>{t.giftcards.info_banner_description}</p>
          <Link
            className={cn(buttonVariants({ variant: "outline" }), "border border-black rounded-[4px] px-6 py-2 mt-4 text-sm md:w-auto hover:opacity-85 text-black")}
            style={{ backgroundColor: commonConfiguration?.sloganTextColor }}
            href={generatePath(Paths.GIFTCARDS)}
            prefetch={false}
          >
            <div className="flex justify-center gap-2 align-middle text-lg font-bold">
              <div>{t.giftcards.info_banner_button}</div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  ) : <></>;
}

