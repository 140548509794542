import { DTMMembershipBanner } from "~/components/DTMMembershipBanner";
import { FanticketBanner } from "~/components/FanticketBanner";
import { GiftcardInfoBanner } from "~/components/GiftcardInfoBanner";
import { useShopConfig } from "./useShopConfig";
import { isDtmShop } from "~/lib/client/isDtmShop";
import { useMemo, JSX } from "react";

enum BannerType {
  fanticket,
  dtmMembership,
  giftcardInfo,
}

interface Banner {
  type: BannerType;
  banner: JSX.Element;
}

const banners: Banner[] = [
  { type: BannerType.fanticket, banner: <FanticketBanner /> },
  { type: BannerType.dtmMembership, banner: <DTMMembershipBanner /> },
  { type: BannerType.giftcardInfo, banner: <GiftcardInfoBanner /> },
];

export function useStartPageBanners(): Banner[] {
  const {
    shopConfig: { giftcardOptions, fanTicket },
  } = useShopConfig();

  return useMemo(() => {
    const isDtm = isDtmShop();

    return banners.filter((banner) => {
      switch (banner.type) {
        case BannerType.fanticket:
          return fanTicket?.isActive;
        case BannerType.dtmMembership:
          return isDtm;
        case BannerType.giftcardInfo:
          return !!giftcardOptions;
      }
    });
  }, [fanTicket, giftcardOptions]);
}
