"use client";
import { PlainMessage } from "@bufbuild/protobuf";
import { EventSeries } from "@egocentric-systems/ts-apis/booking_gateway/v1/events_pb";
import Image from "next/image";
import Link from "next/link";
import { cn, getLargestVariant } from "~/lib/utils";
import { buttonVariants } from "./ui/button";
import { generatePath } from "~/lib/client/utils";
import { Paths } from "~/lib/Paths";
import { useTranslations } from "~/hooks/useTranslations";
import { useShopConfig } from "~/hooks/useShopConfig";
import { useMediaQuery } from "usehooks-ts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTicketSimple } from "@fortawesome/pro-solid-svg-icons";

type ISeriesList = { series: PlainMessage<EventSeries> };

export function SeriesList({ series }: Readonly<ISeriesList>) {
  const t = useTranslations();
  const { shopConfig: config } = useShopConfig();
  const isMobile = useMediaQuery("(max-width: 767px)");
  const thumbnail = getLargestVariant(series.thumbnail);

  return (
    <div className="container" key={series.id}>
      <li key={series.id} className="col-span-1 flex flex-col md:flex-row rounded-[16px] border border-[rgba(0,0,0,0.15)]">
        <span className="sr-only">{series.promoter}</span>

        <Link
          href={generatePath(Paths.SERIES, { seriesId: series.id })}
          className="flex max-w-full items-center justify-center md:max-w-[300px] lg:max-w-[400px]"
          prefetch={false}
        >
          <Image
            src={thumbnail?.url ?? ""}
            alt={series.name}
            width={thumbnail?.width}
            height={thumbnail?.height}
            className={cn("h-full w-full object-cover max-w-[350px]", isMobile ? "rounded-t-[16px]" : "rounded-l-[16px]")}
          />
        </Link>

        <div
          className={cn(
            "relative flex flex-col justify-between w-full py-[16px] bg-cover bg-center",
            isMobile ? "rounded-b-[16px]" : "rounded-r-[16px]"
          )}
          style={{ backgroundImage: `url(${thumbnail?.url})` }}
        >
          <div className={cn("absolute inset-0 bg-gray-50 opacity-95", isMobile ? "rounded-b-[16px]" : "rounded-r-[16px]")} />

          <div className="relative px-4 w-[80%]">
            <Link
              href={generatePath(Paths.SERIES, { seriesId: series.id })}
              className="w-full text-left font-anton text-xl font-bold tracking-anton hover:underline"
              prefetch={false}
            >
              {series.name}
            </Link>
            <p className="w-full text-left text-sm">{series.intro}</p>
            <div className="text-sm font-bold">
              {series.events.length} {series.events.length === 1 ? "Event" : "Events"}
            </div>
          </div>

          <div className="relative px-4  md:absolute md:right-3 md:top-3">
            <Link
              className={cn(buttonVariants({ variant: "outline" }), "flex grow items-center justify-center gap-2 rounded-md border border-black px-2 py-1 text-black md:w-auto hover:opacity-85")}
              style={{ backgroundColor: config?.commonConfiguration?.sloganTextColor }}
              href={generatePath(Paths.SERIES, { seriesId: series.id })}
              prefetch={false}
            >
              <FontAwesomeIcon
                icon={faTicketSimple}
                className="rotate-[155deg]"
              />
              <div>{t.visitSeries}</div>
            </Link>
          </div>
        </div>
      </li>
    </div>
  );

}

