"use client";

import { PlainMessage } from "@bufbuild/protobuf";
import {
  Bundle,
  Event,
} from "@egocentric-systems/ts-apis/booking_gateway/v1/events_pb";
import { getLargestVariant } from "~/lib/utils";
import { generatePath } from "~/lib/client/utils";
import { Paths } from "~/lib/Paths";
import Image from "next/image";
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCircleInfo,
  faTicketSimple,
} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import { useTranslations } from "~/hooks/useTranslations";
import { useShopConfig } from "~/hooks/useShopConfig";

interface ISeries {
  series: PlainMessage<Event | Bundle>;
  promotedSeries?: boolean;
}

export function SeriesSliderCard({
  series,
  promotedSeries,
}: Readonly<ISeries>): JSX.Element {
  const d = useTranslations();
  const {
    shopConfig: { commonConfiguration },
  } = useShopConfig();
  const sloganTextColor = commonConfiguration?.sloganTextColor;
  const thumbnail = getLargestVariant(series.thumbnail);

  const seriesStart = dayjs(series?.start?.time);
  const seriesEnd = dayjs(series?.end?.time);
  const currentTime = dayjs();
  const salesStart = dayjs(series?.salesPeriod?.start?.time);
  const salesEnd = dayjs(series?.salesPeriod?.end?.time);
  const isBundle = series.hasOwnProperty("singleTicket");

  const showBadge =
    currentTime.isAfter(salesStart) && currentTime.isBefore(salesEnd);

  return (
    <>
      <div className="flex-0 relative flex h-full items-center overflow-hidden rounded-t-lg">
        <Link
          href={
            isBundle
              ? generatePath(Paths.TICKETS_BUNDLE, { bundleId: series.id })
              : generatePath(Paths.TICKETS, { eventId: series.id })
          }
          prefetch={false}
          className="hover:pointer h-full"
        >
          <Image
            src={thumbnail?.url ?? ""}
            alt={series.name}
            width={300}
            height={160}
            className="pointer-events-none h-[190px] w-full object-cover"
          />
          {showBadge && (
            <div
              className={`absolute left-2 top-2 rounded-lg px-2 py-1 text-xs font-bold text-white ${
                series?.soldOut ? "bg-rose-500" : "bg-green-500"
              }`}
            >
              {series?.soldOut ? d.sold_out : d.start_page.on_sale}
            </div>
          )}
        </Link>
      </div>
      <div
        style={{ color: promotedSeries ? sloganTextColor : undefined }}
        className="line-clamp-2 h-[42px] shrink-0 px-2 text-md font-bold leading-[130%]"
      >
        {series.name}
      </div>
      <div>
        <div className="flex shrink-0 items-center gap-2 px-2 text-md">
          <FontAwesomeIcon icon={faCalendar} />
          {seriesStart.format("DD.MM.YYYY") === seriesEnd.format("DD.MM.YYYY")
            ? seriesStart.format("DD. MMM YYYY")
            : `${seriesStart.format("DD.")} - ${seriesEnd.format("DD. MMM. YYYY")}`}
        </div>

        <div className="flex w-full gap-2 p-2 text-md">
          <Link
            href={
              isBundle
                ? generatePath(Paths.BUNDLE, { bundleId: series.id })
                : generatePath(Paths.EVENT, { eventId: series.id })
            }
            prefetch={false}
            style={{
              color: promotedSeries ? sloganTextColor : undefined,
              borderColor: promotedSeries ? sloganTextColor : undefined,
            }}
            className="flex grow items-center justify-center gap-1 rounded-md border border-black px-2 py-1"
          >
            <FontAwesomeIcon icon={faCircleInfo} />
            {d.start_page.info}
          </Link>
          <Link
            href={
              isBundle
                ? generatePath(Paths.TICKETS_BUNDLE, { bundleId: series.id })
                : generatePath(Paths.TICKETS, { eventId: series.id })
            }
            prefetch={false}
            className="flex grow items-center justify-center gap-1 rounded-md border border-black px-2 py-1 text-black"
            style={{ backgroundColor: sloganTextColor }}
          >
            <FontAwesomeIcon
              icon={faTicketSimple}
              className="rotate-[155deg]"
            />
            {d.tickets}
          </Link>
        </div>
      </div>
    </>
  );
}
