"use client";

import { PlainMessage } from "@bufbuild/protobuf";
import { EventSeries } from "@egocentric-systems/ts-apis/booking_gateway/v1/events_pb";
import { faBars, faGrid2 } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useShopConfig } from "~/hooks/useShopConfig";
import { translateText, filterHiddenBundleEvents } from "~/lib/client/utils";
import { SeriesList } from "./SeriesList";
import SeriesSlider from "./SeriesSlider";
import { useTranslations } from "~/hooks/useTranslations";
import { useLocalStorage } from "usehooks-ts";
import { buttonVariants } from "./ui/button";
import { cn } from "~/lib/utils";
import { isDtmShop } from "~/lib/client/isDtmShop";
import { useStartPageBanners } from "~/hooks/useStartPageBanners";
import { PromotedSeries } from "./PromotedSeries";

enum ListType {
  grid,
  list,
}

interface ISeries {
  series: Array<PlainMessage<EventSeries>>;
}

const PromotedSeriesIds = [
  "CKNZ6LQ3EH7PJY3ALHNAJGBHVU", // DTM VIP 2025 - egosysmeetsdtm.24sven.rocks
  "OQQQPYACGVHTMPHAQAVM74FLQE", // DTM VIP 2025 - tickets.dtm.com
];

export function Series({ series }: Readonly<ISeries>): JSX.Element {
  const t = useTranslations();
  const banners = useStartPageBanners();

  const {
    shopConfig: { commonConfiguration },
  } = useShopConfig();
  const [listType, setListType] = useLocalStorage("listType", ListType.grid);

  const filteredSeries = series.map((serie) => ({
    ...serie,
    events: filterHiddenBundleEvents(serie.events),
  }));

  const promotedSeries = filteredSeries.find((eventSeries) =>
    PromotedSeriesIds.includes(eventSeries.id),
  );

  const groupedSeries = filteredSeries
    .filter((eventSeries) => !PromotedSeriesIds.includes(eventSeries.id))
    .reduce(
      (acc, eventSeries, index) => {
        if (index % 3 === 0) {
          return acc.concat([[eventSeries]]);
        }

        return acc.map((group, i) =>
          i === acc.length - 1 ? group.concat(eventSeries) : group,
        );
      },
      [] as Array<Array<PlainMessage<EventSeries>>>,
    );
  return (
    <>
      <section className="mx-auto mt-12 space-y-4">
        {promotedSeries && <PromotedSeries series={promotedSeries} />}
        <div className="container flex justify-between">
          <div>
            <h1 className="mb-4 font-anton text-xl text-black md:text-3xl md:font-light">
              {t.start_page.get_tickets_now}
            </h1>
            {isDtmShop() && (
              <p className="text-md">{t.start_page.intro_get_dtm_tickets}</p>
            )}
          </div>

          <div className="hidden gap-1 md:flex">
            <div
              className={cn(
                buttonVariants({ variant: "link" }),
                listType === ListType.grid
                  ? "bg-[var(--hover-bg)]"
                  : "hover:bg-[var(--hover-bg)]",
                "p-1 hover:text-black",
              )}
              style={
                {
                  "--hover-bg": commonConfiguration?.sloganTextColor,
                } as React.CSSProperties
              }
              onClick={() => setListType(ListType.grid)}
            >
              <FontAwesomeIcon icon={faGrid2} />
            </div>
            <div
              className={cn(
                buttonVariants({ variant: "link" }),
                listType === ListType.list
                  ? "bg-[var(--hover-bg)]"
                  : "hover:bg-[var(--hover-bg)]",
                "p-1 hover:text-black",
              )}
              style={
                {
                  "--hover-bg": commonConfiguration?.sloganTextColor,
                } as React.CSSProperties
              }
              onClick={() => setListType(ListType.list)}
            >
              <FontAwesomeIcon icon={faBars} />
            </div>
          </div>
        </div>
        <p className="container text-sm md:text-md">
          {translateText(commonConfiguration?.sloganSubTitle)}
        </p>

        <ul className="space-y-4">
          {groupedSeries.map((groupOfSeries, groupIndex) => {
            return (
              <>
                {groupOfSeries.map((series) => (
                  <>
                    {listType === ListType.grid && (
                      <SeriesSlider series={series} />
                    )}
                    {listType === ListType.list && (
                      <SeriesList series={series} />
                    )}
                  </>
                ))}
                {banners.at(groupIndex)?.banner}
              </>
            );
          })}
          {banners.slice(groupedSeries.length).map((banner) => banner.banner)}
        </ul>
      </section>
    </>
  );
}
