import { PlainMessage } from "@bufbuild/protobuf";
import { EventSeries } from "@egocentric-systems/ts-apis/booking_gateway/v1/events_pb";
import { SeriesSliderCard } from "./SeriesSliderCard";
import Carousel from "react-simply-carousel";
import { useState } from "react";
import { cn } from "~/lib/utils";

interface ISeriesCarousel {
  series: PlainMessage<EventSeries>;
  promotedSeries?: boolean;
}

export function SeriesCarousel({
  series,
  promotedSeries,
}: Readonly<ISeriesCarousel>): JSX.Element {
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const combinedEvents = [...series.bundles, ...series.events];

  return (
    <Carousel
      containerProps={{
        style: {
          width: "calc(100% + 4rem)",
          userSelect: "none",
          alignItems: "end",
          overflow: "hidden",
        },
      }}
      itemsListProps={{
        style: {
          alignItems: "end",
          justifyContent: "space-between",
          gap: "1rem",
        },
      }}
      activeSlideProps={{
        className: "h-[340px]",
      }}
      responsiveProps={[
        {
          itemsToShow: 2,
          itemsToScroll: 3,
          minWidth: 768,
        },
      ]}
      speed={400}
      itemsToShow={6}
      swipeTreshold={10}
      easing="ease-in-out"
      activeSlideIndex={activeSlide}
      onRequestChange={(slideIndex) => {
        setTimeout(() => {
          setActiveSlide(slideIndex);
        });
      }}
      infinite
      centerMode
      updateOnItemClick
      preventScrollOnSwipe
      disableNavIfAllVisible
      persistentChangeCallbacks
    >
      {combinedEvents.map((serie) => (
        <div
          key={series.id}
          role="tabpanel"
          className={cn(
            promotedSeries
              ? "border-black bg-black text-white"
              : "bg-gray-200 text-black",
            "flex h-[310px] w-[290px] flex-col justify-between rounded-lg border transition-all duration-300 ease-in-out md:hover:h-[340px]",
          )}
        >
          <SeriesSliderCard series={serie} promotedSeries={promotedSeries} />
        </div>
      ))}
    </Carousel>
  );
}
